<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine the value of the unknown variable in each of the expressions below. Report both
        answers using 3 significant figures.
      </p>

      <p class="mb-2">
        a) <stemble-latex :content="`$${coef1.toInt()}\\,\\text{s}^{${exponent1.toInt()}}=$`" />
        <number-value :value="result1" />
      </p>

      <calculation-input
        v-model="inputs.valueOfS"
        class="mb-5"
        prepend-text="$\text{s:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b)
        <stemble-latex
          :content="`$\\text{C}=${coef2.toInt()}^{(1/${exponentDenominator.toInt()})}$`"
        />
      </p>
      <calculation-input
        v-model="inputs.valueOfC"
        prepend-text="$\text{C:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'SolvingEquationsWithExponents1',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        valueOfS: null,
        valueOfC: null,
      },
    };
  },
  computed: {
    coef1() {
      return this.taskState.getValueBySymbol('coef1').content;
    },
    exponent1() {
      return this.taskState.getValueBySymbol('exponent1').content;
    },
    result1() {
      return this.taskState.getValueBySymbol('result1').content;
    },
    coef2() {
      return this.taskState.getValueBySymbol('coef2').content;
    },
    exponentDenominator() {
      return this.taskState.getValueBySymbol('exponentDenominator').content;
    },
  },
};
</script>
